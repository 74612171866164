/* eslint-disable react/prop-types */
import React from 'react'
import { graphql } from 'gatsby'

// Meta Component
import Meta from 'components/Meta'

// Package Route
import Package from 'routes/templates/Package'

// Gatsby SEO Head
export function Head({ data }) {
  return (
    <Meta tags={data.package.seoMetaTags.tags} hideMeta hideTitle>
      <title>{`${data.package.name} - Pocketworks Mobile Tools and Resources`}</title>
      <meta name="description" content={data.package.oneLineSummary} />
    </Meta>
  )
}

export default Package

export const query = graphql`
  query PackageQuery($slug: String!) {
    package: datoCmsPackage(slug: { eq: $slug }) {
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      name
      straplineNode {
        childMarkdownRemark {
          html
        }
      }
      introNode {
        childMarkdownRemark {
          html
        }
      }
      contentBlocks {
        ... on DatoCmsQuote {
          id
          image {
            url
            gatsbyImageData(placeholder: BLURRED)
            fluid(
              maxWidth: 1024
              imgixParams: { fm: "jpg", auto: "compress" }
            ) {
              ...GatsbyDatoCmsFluid
            }
          }
          name
          role
          quote
        }
        ... on DatoCmsFeatureWithImage {
          id
          title
          hasTopSeparator
          hasBottomSeparator
          backgroundClass
          bodyNode {
            childMarkdownRemark {
              html
            }
          }
          boxes {
            title
            content
            contentNode {
              childMarkdownRemark {
                html
              }
            }
            cssClass
            iconClass
            columnClass
          }
          image {
            url
            gatsbyImageData(placeholder: BLURRED)
            fluid(
              maxWidth: 1024
              imgixParams: { fm: "jpg", auto: "compress" }
            ) {
              ...GatsbyDatoCmsFluid
            }
          }
        }
      }
      slug
      oneLineSummary
      price
      payhipId
      guarantee
      guaranteeNode {
        childMarkdownRemark {
          html
        }
      }
      heroImage {
        url
        gatsbyImageData(
          placeholder: TRACED_SVG
          imgixParams: { fm: "png", auto: "compress", maxW: 800 }
        )
      }
      contextPhoto {
        url
        gatsbyImageData(
          placeholder: BLURRED
          imgixParams: {
            w: "1280"
            h: "960"
            fit: "crop"
            ar: "4:4"
            fm: "jpg"
            auto: "compress"
            duotone: "f99f53,FA8072"
            duotoneAlpha: 6
          }
        )
        fluid(maxWidth: 1024, imgixParams: { fm: "jpg", auto: "compress" }) {
          ...GatsbyDatoCmsFluid
        }
      }
    }
  }
`
