import PropTypes from 'prop-types'
import React from 'react'
import { Link } from 'gatsby'
import {
  Section,
  Container,
  Columns,
  Hero,
  Box,
  Card,
} from 'react-bulma-components'

// Components
import SiteNav from 'components/navbar'
import Layout from 'components/layout'
import { GatsbyImage, StaticImage } from 'gatsby-plugin-image'
import { GatsbySeo, ProductJsonLd } from 'gatsby-plugin-next-seo'

// Page Components

function Package({ data }) {
  const renderBloc = (block) => {
    console.log(block)
    switch (block.id.split('-')[0]) {
      case 'DatoCmsFeatureWithImage':
        return renderFeaturedBlock(block)
      case 'DatoCmsQuote':
        return renderQuote(block)
      default:
        return null
    }
  }

  const renderQuote = (block) => (
    <Section className="container">
      <Columns className="is-centered">
        <Columns.Column className="is-narrow is-3">
          <GatsbyImage
            className="image box has-shadow is-rounded"
            image={block.image ? block.image.gatsbyImageData : null}
            alt={block.name || 'Pocketworks'}
          />
        </Columns.Column>
        <Columns.Column className="">
          <div className="testimonial-quote ml-5">
            <p className="is-size-3">{block.quote}</p>
          </div>
          <p className="ml-5 mt-3">
            {block.name}, {block.role}
          </p>
        </Columns.Column>
      </Columns>
    </Section>
  )

  const renderFeaturedBlock = (block) => (
    <>
      {block.hasTopSeparator ? (
        <Section
          className={`${
            block.backgroundClass === 'is-dark-bg'
              ? 'has-background-grey-darker'
              : ''
          } p-0 m-0 is-small w-full`}
        >
          <div
            className={`${
              block.backgroundClass === 'is-dark-bg'
                ? 'has-background-white-ter'
                : 'has-background-grey-darker'
            } is-rounded-separator`}
          />
        </Section>
      ) : null}
      <Section
        key={block.id}
        className={`${block.backgroundClass} content mb-0`}
      >
        <Container className="content mt-6">
          <Columns>
            <Columns.Column className="is-6">
              <h2 className="title">{block.title}</h2>
              <div
                className="content"
                dangerouslySetInnerHTML={{
                  __html: block.bodyNode.childMarkdownRemark.html,
                }}
              />
            </Columns.Column>
            <Columns.Column className="pl-6 is-6">
              <GatsbyImage
                className=""
                image={block.image ? block.image.gatsbyImageData : null}
                alt={block.title || 'Pocketworks'}
              />
            </Columns.Column>
            {block.boxes ? (
              <Columns.Column className="is-12 pt-6">
                <Columns>
                  {block.boxes.map((box) => (
                    <Columns.Column className={box.columnClass}>
                      <div className={box.cssClass}>
                        <Columns>
                          <Columns.Column
                            className={
                              box.iconClass !== '' ? 'is-narrow' : 'is-hidden'
                            }
                          >
                            <span className="icon has-text-dark">
                              <i className={box.iconClass} />
                            </span>
                          </Columns.Column>
                          <Columns.Column>
                            <h4 className="title has-text-weight-semibold">
                              {box.title}
                            </h4>
                            <div
                              dangerouslySetInnerHTML={{
                                __html:
                                  box.contentNode.childMarkdownRemark.html,
                              }}
                            />
                          </Columns.Column>
                        </Columns>
                      </div>
                    </Columns.Column>
                  ))}
                </Columns>
              </Columns.Column>
            ) : null}
          </Columns>
        </Container>
      </Section>
      {block.hasBottomSeparator ? (
        <Section
          className={`${
            block.backgroundClass === 'is-dark-bg'
              ? ''
              : 'has-background-grey-darker'
          } p-0 m-0 is-small w-full`}
        >
          <div
            className={`${
              block.backgroundClass === 'is-dark-bg'
                ? 'has-background-grey-darker'
                : ''
            } is-rounded-separator`}
          />
        </Section>
      ) : null}
    </>
  )

  return (
    <>
      <GatsbySeo
        title={`${data.package.name} Service`}
        description={data.package.description}
      />
      <Layout>
        <Hero className="is-small pb-6 has-background-grey-lighter">
          <Hero.Header>
            <SiteNav fullLogo />
          </Hero.Header>
          <Hero.Body className="pt-6 mt-6">
            <Container className="content">
              <Columns className="is-vcentered">
                <Columns.Column className="is-7">
                  <h1 className="title mb-2 pb-0">{data.package.name}</h1>
                  <h2
                    className="subtitle is-size-4 has-text-weight-normal pt-5"
                    dangerouslySetInnerHTML={{
                      __html:
                        data.package.straplineNode.childMarkdownRemark.html,
                    }}
                  />
                  <ProductJsonLd
                    name={`${data.package.name} Service`}
                    description={data.package.description}
                    brand="Pocketworks"
                    image={[data.package.heroImage.url]}
                    offers={{
                      '@type': 'Offer',
                      price: data.package.price,
                      priceCurrency: 'GBP',
                    }}
                  />
                  <div
                    className="is-size-5 mb-5"
                    dangerouslySetInnerHTML={{
                      __html: data.package.introNode.childMarkdownRemark.html,
                    }}
                  />
                  <p>
                    {data.package.price ? (
                      <span className="has-text-dark has-text-weight-normal">
                        Cost: {data.package.payhipId ? '' : ''}
                        &pound;
                        {new Intl.NumberFormat().format(data.package.price)}
                      </span>
                    ) : (
                      'FREE'
                    )}
                  </p>
                  {data.package.payhipId ? (
                    <>
                      <div>
                        <Link
                          className="button is-medium is-leafy"
                          target="_blank"
                          to={`https://payhip.com/buy?link=${data.package.payhipId}`}
                        >
                          Purchase Now
                        </Link>
                        <Link
                          to="/contact"
                          className="button is-text is-medium"
                        >
                          Arrange a consultation
                        </Link>
                      </div>
                      <div className="pt-3">
                        <span className="is-size-6">Payment via PayHip.</span>
                      </div>
                    </>
                  ) : (
                    <Link to="/contact" className="button is-leafy is-medium">
                      Arrange a consultation
                    </Link>
                  )}
                </Columns.Column>
                <Columns.Column className="is-4 is-offset-1">
                  <GatsbyImage
                    image={data.package.heroImage.gatsbyImageData}
                    alt={data.package.name || 'Pocketworks'}
                  />
                </Columns.Column>
              </Columns>
            </Container>
          </Hero.Body>
        </Hero>

        <Section
          className="is-hidden is-medium has-bg-image blog-hero has-shadow-top-bottom w-full"
          style={{
            backgroundImage: data.package.contextPhoto
              ? `url(${data.package.contextPhoto.url}?fm=jpg&w=1800&duotone=f99f53,FA8072&duotone-alpha=6)`
              : '',
          }}
        >
          <Columns>
            <Columns.Column className="is-6">
              <Box className="has-background-grey-darker">
                <h3
                  className="subtitle is-size-3-desktop is-size-4-mobile has-text-light p-5"
                  dangerouslySetInnerHTML={{
                    __html: data.package.introNode.childMarkdownRemark.html,
                  }}
                />
              </Box>
            </Columns.Column>
          </Columns>
        </Section>
        {data.package.contentBlocks.map((block) => renderBloc(block))}
        <Section>
          <Container className="box is-rounded has-shadow">
            <Columns className="is-vcentered">
              <Columns.Column>
                {data.package.payhipId ? (
                  <>
                    <div>
                      <Link
                        className="button is-medium is-leafy"
                        target="_blank"
                        to={`https://payhip.com/buy?link=${data.package.payhipId}`}
                      >
                        Purchase Now
                      </Link>
                      <Link to="/contact" className="button is-text is-medium">
                        Arrange a consultation
                      </Link>
                    </div>
                    <div className="p-3">
                      <span className="is-size-6 has-text-grey">
                        Payment via PayHip.
                      </span>
                    </div>
                  </>
                ) : (
                  <Link to="/contact" className="button is-leafy is-medium">
                    Arrange a consultation
                  </Link>
                )}
              </Columns.Column>
              <Columns.Column>
                {data.package.guarantee !== '' ? (
                  <div
                    className="content"
                    dangerouslySetInnerHTML={{
                      __html:
                        data.package.guaranteeNode.childMarkdownRemark.html,
                    }}
                  />
                ) : null}
              </Columns.Column>
            </Columns>
          </Container>
        </Section>
        <Section>
          <StaticImage src="https://www.datocms-assets.com/20955/1721052407-pocketworks-team-sand.png" />
        </Section>
      </Layout>
    </>
  )
}
Package.propTypes = {
  data: PropTypes.object,
}
export default Package
